import { template as template_31032fb1b5714809993444222419ecdd } from "@ember/template-compiler";
import NotificationsTracking from "discourse/components/notifications-tracking";
const GroupNotificationsTracking = template_31032fb1b5714809993444222419ecdd(`
  <NotificationsTracking
    @onChange={{@onChange}}
    @levelId={{@levelId}}
    @showCaret={{false}}
    @showFullTitle={{false}}
    @prefix="groups.notifications"
    class="group-notifications-tracking"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GroupNotificationsTracking;
