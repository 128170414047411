import { template as template_ef9fb9fa5d164ca4919091e93aea3b1a } from "@ember/template-compiler";
const WelcomeHeader = template_ef9fb9fa5d164ca4919091e93aea3b1a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
