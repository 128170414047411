import { template as template_c343abde684148209e3a6b4fe486bf6c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_c343abde684148209e3a6b4fe486bf6c(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
