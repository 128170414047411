import { template as template_801e59b0f5bd44ddada8e419708eaa78 } from "@ember/template-compiler";
const FKControlMenuContainer = template_801e59b0f5bd44ddada8e419708eaa78(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
