import { template as template_81eaaabeeb4f4df0b9840fd5cb824381 } from "@ember/template-compiler";
const FKText = template_81eaaabeeb4f4df0b9840fd5cb824381(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
